<template>
  <section class="content px-0 py-2 my-2" name="MassiveOrders">
    <v-layout wrap>
      <v-flex xs12>
        <AppTitle title="Whatsapp clientes" subtitle="---" />
      </v-flex>
      <template v-if="!loading">
        <v-flex xs12 sm2>
          <v-dialog ref="dialog" v-model="modalDate" :return-value.sync="date" persistent lazy full-width width="290px">
            <v-text-field
              slot="activator"
              v-model="date"
              readonly
              prepend-inner-icon="date_range"
              placeholder="Informacion del día..."
              label="Informacion del día..."
              persistent-hint
              solo
            ></v-text-field>
            <v-date-picker v-model="dateDatePicker" color="secondary" type="date" locale="es-es" scrollable>
              <v-btn flat color="back" @click="modalDate = false">Volver</v-btn>
              <v-spacer></v-spacer>
              <v-btn flat color="accent" @click="$refs.dialog.save(dateDatePicker)">Listo</v-btn>
            </v-date-picker>
          </v-dialog>
        </v-flex>
        <v-flex xs12 sm2>
          <v-select v-model="wsp" :items="wordsList" label="Palabras" item-text="name" return-object solo></v-select>
        </v-flex>
        <v-flex xs12 sm6>
          <v-textarea
            v-model="wsp.words"
            solo
            auto-grow
            placeholder="Palabras a filtrar"
            hint="Seperar con comas las palabras a filtrar"
            rows="1"
            single-line
          ></v-textarea>
        </v-flex>
        <v-flex xs12 sm2>
          <v-btn color="info" block large class="ma-0" @click="filterOrders">Filtrar</v-btn>
        </v-flex>
      </template>
      <v-flex xs12>
        <v-card v-if="wsp.body">
          <AppCardTitle :title="`${orders.length} por enviar`" />
          <v-divider></v-divider>
          <v-data-table
            v-model="selected"
            item-key="id"
            :headers="headers"
            :items="orders"
            :loading="loading"
            rows-per-page-text="Pedidos por pagina"
            no-data-text="No hay pedidos para generar boleta/factura"
            class="elevation-0"
            hide-actions
          >
            <template slot="items" slot-scope="props">
              <tr :active="props.selected">
                <td @click="props.selected = !props.selected">
                  <v-icon
                    v-if="props.item.send === 0 && !validateSend(props.item.codes)"
                    color="info"
                    @click="SendWsp(props.item)"
                    >far fa-play-circle</v-icon
                  >
                  <v-icon v-else-if="props.item.send === 1" color="warning">fas fa-spinner fa-spin</v-icon>
                  <v-icon v-else-if="props.item.send === 2" color="success">fas fa-check-circle</v-icon>
                  <v-icon v-else-if="props.item.send === 3" color="error">far fa-money-bill-alt</v-icon>
                  <v-icon v-else-if="props.item.send === 6" color="error">fas fa-bug</v-icon>
                  <v-icon v-else-if="validateSend(props.item.codes)" color="success">fas fa-check-circle</v-icon>
                </td>
                <td>
                  <a :href="`/pedido/${props.item.id}/`" target="_blank">{{ props.item.id }}</a>
                </td>
                <td>
                  {{ props.item.dateShipping }}
                </td>
                <td>
                  <div v-html="props.item.message" />
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </section>
</template>

<script>
import { SEND_WSP, MASSIVE_ORDER, WSP_MSJ } from '../../config'
import AppTitle from '../useful/title.vue'
import AppCardTitle from '../useful/titleCard.vue'

export default {
  name: 'WspSpecial',
  components: {
    AppTitle,
    AppCardTitle
  },
  data() {
    return {
      modalDate: false,
      loading: false,
      dialogEmail: false,
      date: this.$moment()
        .add(2, 'days')
        .format('YYYY-MM-DD'),
      dateDatePicker: null,
      emailActive: null,
      subject: null,
      wordsList: [],
      wsp: {
        words: '',
        name: '',
        id: '',
        body: ''
      },
      emailLists: [
        { id: 0, name: 'Por defecto', url: 'fecha_importante_otro' },
        { id: 1, name: 'Cumpleaños', url: 'fecha_importante_cumpleano' },
        { id: 2, name: 'Aniversarios', url: 'fecha_importante_aniversario' },
        { id: 3, name: 'Especial Navidad', url: 'fecha_importante_navidad' },
        { id: 4, name: '14 de Febrero', url: 'dia_de_los_enamorados' }
      ],
      words:
        'amor, valentin, anticipado, adelantado, enamorados, te amo, dia del amor, 14 de febrero, juntos, feliz dia, los dos',
      orders: [],
      ordersAll: [],
      selected: [],
      pagination: { rowsPerPage: 50 },
      rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }],
      headers: [
        {
          text: 'Estado',
          align: 'left',
          sortable: false,
          value: 'order'
        },
        {
          text: 'Pedido',
          align: 'left',
          sortable: false,
          value: 'order'
        },
        {
          text: 'Fecha de entrega',
          align: 'left',
          sortable: false,
          value: 'date_shipping'
        },
        {
          text: 'Mensaje',
          align: 'left',
          sortable: false,
          value: 'message'
        }
      ]
    }
  },
  watch: {
    date() {
      this.getOrders()
    },
    wsp() {
      this.filterOrders()
    }
  },
  mounted() {
    this.getMsjWsp()
    this.getOrders()
  },
  methods: {
    async getMsjWsp() {
      try {
        const res = await this.$http.get(WSP_MSJ)
        this.wordsList = res.data
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async getOrders() {
      try {
        const res = await this.$http.get(`${MASSIVE_ORDER}${this.date}`)
        this.orders = res.data
        this.ordersAll = res.data
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async SendWsp(item) {
      item.send = 1
      try {
        const res = await this.$http({
          method: 'post',
          url: SEND_WSP,
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          data: this.$qs.stringify({
            p: 'sendWspSpecial',
            item
          })
        })
        if (res.data.send) {
          this.sendWhatsApp(res.data.code, {
            name: item.client,
            phone: item.phone
          })
          item.send = 2
        }
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    filterOrders() {
      const words = this.wsp.words.split(',')
      this.orders = []
      if (words !== '') {
        this._.forEach(this.ordersAll, item => {
          this._.forEach(words, word => {
            if (this._.includes(item.message, word)) {
              this.orders.push(item)
              return false
            }
          })
        })
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Pedidos Filtrados'
        })
      }
    },
    sendWhatsApp(code, item) {
      let msj = this.wsp.body
      const name = item.name.split(' ')
      msj = msj.replace('{name}', name[0])
      msj = msj.replace('{code}', code)
      const url = `https://api.whatsapp.com/send?phone=${item.phone}&text=${msj}`
      window.open(url)
    },
    validateSend(codes) {
      let res = false
      codes.forEach(e => {
        if (e.type === 1) {
          res = true
        }
      })
      return res
    }
  }
}
</script>
